import { Box, Image, Main, ResponsiveContext } from "grommet";
import React from "react";
import SiteLayout from "../layout";
import styled from "styled-components";
import PaymentForm from "../components/payment-form";
export default function Trans(props) {
  const size = React.useContext(ResponsiveContext);
  let transId = props.location.pathname.replace("/t/", "");
  transId = transId.replace("/", "");

  React.useEffect(() => {
    const jsScript = document.createElement("script");
    // Test UPG Url
    // jsScript.src =
    //   'https://upgstaglightbox.egyptianbanks.com/js/Lightbox.js'

    // Live UPG Url
    jsScript.src = "https://upglightbox.egyptianbanks.com/Lightbox.js";
    jsScript.async = true;
    setTimeout(() => {
      document.body.appendChild(jsScript);
    }, 100);
  }, []);

  React.useEffect(() => {
    const jsScript = document.createElement("script");
    jsScript.src =
      "https://banquemisr.gateway.mastercard.com/checkout/version/57/checkout.js";
    jsScript.setAttribute(
      "data-error",
      "https://php.onelink2pay.co/api/v2/errorcallback/"
    );
    jsScript.setAttribute(
      "complete",
      "https://php.onelink2pay.co/api/v2/completecallback/"
    );
    jsScript.async = true;
    setTimeout(() => {
      document.body.appendChild(jsScript);
    }, 100);
  }, []);

  React.useEffect(() => {
    document.getElementById("preloading").innerHTML = "";
  });

  console.log("size", size);

  return (
    <SiteLayout>
      <WrapConontroller>
        <ResponsiveContext.Consumer>
          {(size) => (
            <Main direction="row">
              <Box
                width={size === "small" ? "100%" : "580px"}
                pad={{ left: "medium" }}
              >
                <Box justify="center" align="start" pad="small" height="80px">
                  <Image
                    src={require("../images/logo.svg")}
                    width="70px"
                    height="23px"
                    alt="logo"
                  />
                </Box>
                <PaymentForm transId={transId} />
              </Box>
              {size !== "small" && (
                <Box flex={{ grow: 2 }} className="controlDiv">
                  <Image
                    src={require("../images/elipse-background.svg")}
                    className="controlElipse"
                    alt="controlElipse "
                  />
                  <Box
                    className="controlIcon"
                    fill
                    justify="center"
                    align="center"
                  >
                    <Image
                      src={require("../images/mobile-payment.svg")}
                      alt="online payment "
                      width={460}
                      height={420}
                    />
                  </Box>
                </Box>
              )}
            </Main>
          )}
        </ResponsiveContext.Consumer>
      </WrapConontroller>
    </SiteLayout>
  );
}

const WrapConontroller = styled.div`
  height: 100vh;
  .controlDiv {
    position: relative;
    overflow: hidden;
    .controlElipse {
      position: absolute;
      width: calc(100vh + 1000px);
      height: calc(100vh + 1000px);
      bottom: -250px;
      left: 10px;
      max-width: initial;
      z-index: 100;
    }
    .controlIcon {
      position: absolute;
      z-index: 101;
      left: 150px;
      min-width: 300px;
    }
  }
`;
