import axios from "axios";
import crypto from "crypto";
export async function createSession(transId) {
  const info = {
    transId,
  };
  const link = "https://php.onelink2pay.co/api/v2/session/create";
  return await axios({
    method: "post",
    url: link,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      accept: "application/json",
    },
    data: serialize(info),
  });
}

export function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
function base64ToHex(str) {
  const raw = atob(str);
  let result = "";
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    result += hex.length === 2 ? hex : "0" + hex;
  }
  return result;
}
function hex_to_ascii(str1) {
  var hex = str1.toString();
  var str = "";
  for (var n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
}

export function generateUPGSecureHash(
  amount,
  dateTime,
  merchantId,
  merchantRef,
  terminalId,
  secureHash
) {
  const obj = {
    Amount: amount,
    DateTimeLocalTrxn: dateTime,
    MerchantId: merchantId,
    MerchantReference: merchantRef,
    TerminalId: terminalId,
  };
  const sortedObj = serialize(obj);
  const hmac = crypto.createHmac("sha256", hex_to_ascii(secureHash));
  console.log(process.env);
  const data = hmac.update(sortedObj);
  console.log("data", data);
  const gen_hmac = data.digest("base64");
  console.log(base64ToHex(gen_hmac));
}

// MID: 10356532694
// Secure Key: 35396331343364392D396566622D343363622D626534352D333764386332623836663264
// TID: 70473567

//d47912d87ee4c9b7f47aa09a1b955303da7ffc53e75d481439da57095b918b2a
//d47912d87ee4c9b7f47aa09a1b955303da7ffc53e75d481439da57095b918b2a
