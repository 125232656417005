import { Box, Text } from "grommet";
import React from "react";
import TransDisplay from "./trans-display";

export default function PaymentForm({ transId }) {
  // if (loading) return <LoadingView />;
  // if (error) return <ErrorView error={error.message} />;

  return (
    <Box justify="center" fill="vertical">
      <TransDisplay transId={transId} />
      <Box pad="medium">
        {/* <Box direction="row">
          <Box flex="grow">
            <Link>
              <Anchor as="span">
                <Text size="small" color="light-6">
                  Terms & Conditions
                </Text>
              </Anchor>
            </Link>
          </Box>
          <Box flex="grow">
            <Link>
              <Anchor as="span">
                <Text size="small" color="light-6">
                  Privacy Policy
                </Text>
              </Anchor>
            </Link>
          </Box>
        </Box> */}
        <Box pad={{ vertical: "medium" }}>
          <Text size="small" color="light-6">
            Copyright © {new Date().getFullYear()} Cloudx9 . All Rights
            Reserved.
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
