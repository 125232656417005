import {
  Box,
  Button,
  Heading,
  Image,
  Layer,
  RadioButtonGroup,
  Text,
} from "grommet";
import React, { Component } from "react";
import { createSession } from "../services/helpers";
import LoadingView from "./loading";

export default class TransDisplay extends Component {
  state = {
    sessionData: null,
    agreed: false,
    isOnFrame: false,
    paymentType: "Credit/Debit card",
  };
  componentDidMount() {
    this.initSesssion();
  }

  async initSesssion() {
    try {
      let session = await createSession(this.props.transId);

      let sessionData = session.data;

      if (window.Checkout) {
        window.Checkout.configure({
          session: {
            id: sessionData.session,
          },
          interaction: {
            merchant: {
              name: `${sessionData.first_name} ${sessionData.last_name}`,
              address: {
                line1: "Cairo, Egypt",
                line2: "1234 Example Town",
              },
            },
            displayControl: {
              orderSummary: "SHOW",
              paymentConfirmation: "HIDE",
              billingAddress: "HIDE",
              customerEmail: "HIDE",
              shipping: "HIDE",
            },
          },
        });
        this.setState({
          sessionData,
        });
      }
      if (window.Lightbox) {
        window.Lightbox.Checkout.configure = {
          OrderId: "",
          paymentMethodFromLightBox: 0,
          MID: sessionData.upg_mID,
          TID: sessionData.upg_tID,
          SecureHash: sessionData.upg_secureHash,
          TrxDateTime: sessionData.upg_dT,
          AmountTrxn: sessionData.amount * 100,
          MerchantReference: sessionData.upg_rNo,
          ReturnUrl: sessionData.upg_returnUrl,
          completeCallback: function (data) {
            window.location.href =
              ".https://php.onelink2pay.co/trans/complete/" +
              sessionData.upg_rNo;
            //your code here
          },
          errorCallback: this.__upg_completeCallback.bind(this),
          cancelCallback: this.__upg_cancelCallback.bind(this),
        };
      }
    } catch (e) {
      if (typeof window !== "undefined") {
        console.log(e);
        window.location = "/error?e=" + e.message;
      }
    }
  }

  __upg_completeCallback = function (data) {
    console.log("__upg_completeCallback");
    this.setState({
      isOnFrame: false,
    });
  };
  __upg_cancelCallback = function (data) {
    console.log("__upg_cancelCallback");
    this.setState({
      isOnFrame: false,
    });
  };

  render() {
    let { sessionData, isOnFrame } = this.state;
    return (
      <>
        {sessionData ? (
          <>
            {sessionData.is_paid ? (
              <>
                <Box pad={{ vertical: "medium" }}>
                  <Text color="light-6">{"Welcome"}</Text>
                  <Text weight="bold">{"Onelink User"}</Text>
                </Box>
                <Box pad={{ vertical: "medium" }} direction="row">
                  <Box
                    width="48px"
                    height="48px"
                    background={{
                      color: "#00BE00",
                      opacity: 0.2,
                    }}
                    round="50%"
                    align="center"
                    direction="row"
                    justify="center"
                    pad={{ top: "20px" }}
                  >
                    <Image
                      src={require("../images/done.png")}
                      width="38px"
                      height="38px"
                      alt="operation done"
                    />
                  </Box>
                  <Box pad={{ horizontal: "small" }}>
                    <Text weight="bold" color="neutral-1">
                      {"Payment Successful"}
                    </Text>
                    <Text>{`Please check your email for payment details.`}</Text>
                  </Box>
                </Box>
                <Box pad={{ vertical: "xlarge" }}>
                  <Box
                    margin={{ vertical: "medium" }}
                    pad="small"
                    border={{
                      color: "brand",
                      side: "left",
                      size: "5px",
                    }}
                  >
                    <Text size="small">
                      {"Paid to "}
                      <Text size="small" color="brand">
                        {sessionData.merchant}
                      </Text>
                    </Text>
                    <Heading
                      level="1"
                      margin="none"
                    >{`E£ ${sessionData.amount}`}</Heading>
                  </Box>
                </Box>
              </>
            ) : (
              <Box flex style={{ minHeight: "500px" }}>
                <Box pad={{ vertical: "small" }}>
                  <Text color="light-6">{"Welcome"}</Text>
                  <Text weight="bold">{`OneLink User`}</Text>
                </Box>
                <Box pad={{ vertical: "small" }}>
                  <Box
                    margin={{ vertical: "medium" }}
                    pad="small"
                    border={{
                      color: "brand",
                      side: "left",
                      size: "5px",
                    }}
                  >
                    <Text size="small">
                      {"Please pay to "}
                      <Text size="small" color="brand">
                        {sessionData.merchant}
                      </Text>
                    </Text>
                    <Heading
                      level="2"
                      margin="none"
                    >{`E£ ${sessionData.amount}`}</Heading>
                  </Box>
                  <Heading level="3" color="brand">
                    {"How would you like to pay?"}
                  </Heading>
                  <RadioButtonGroup
                    name="doc"
                    options={["Credit/Debit card", "Wallets/Meza card"]}
                    value={this.state.paymentType}
                    onChange={(event) => {
                      this.setState({
                        paymentType: event.target.value,
                        isOnFrame: false,
                      });
                    }}
                  />
                </Box>
                {/* <Box pad={{ vertical: "medium" }}>
                  <CheckBox
                    checked={agreed}
                    label={
                      " I accept to the Terms & Conditions and Privacy Policy."
                    }
                    name="terms-conditions"
                    onChange={(v) =>
                      this.setState({ agreed: v.target.checked })
                    }
                  />
                </Box> */}
                <Box pad={{ vertical: "medium" }}>
                  <Text size="small" color="brand">
                    {sessionData.merchant}'s Terms & Conditions May apply
                  </Text>
                  <Text size="small" margin={{ vertical: "small" }}>
                    All transactions are done through secured hosted session at
                    Banque Misr servers
                  </Text>
                </Box>

                <Box pad={{ vertical: "medium" }} align="center">
                  <Button
                    primary
                    size="large"
                    label={<Text color="white">Pay Now</Text>}
                    disabled={isOnFrame}
                    onClick={() => {
                      this.setState({ isOnFrame: true }, () => {
                        setTimeout(() => {
                          this.setState({ isOnFrame: false });
                        }, 10000);
                      });
                      if (this.state.paymentType === "Credit/Debit card") {
                        window.Checkout.showLightbox();
                      } else {
                        console.log(window.Lightbox);
                        window.Lightbox.Checkout.showLightbox();
                      }
                    }}
                  />
                </Box>
              </Box>
            )}
          </>
        ) : (
          <LoadingView />
        )}

        {this.state.isOnFrame && (
          <Layer
            onEsc={() => {
              this.setState({ isOnFrame: false });
            }}
            onClickOutside={() => {
              this.setState({ isOnFrame: false });
            }}
            modal
            style={{ zIndex: 111 }}
          >
            <Box
              width="medium"
              height="medium"
              pad={{ vertical: "large", horizontal: "large" }}
              style={{ zIndex: 99 }}
            >
              <LoadingView text={"Loading your secure payment"} />
            </Box>
          </Layer>
        )}
      </>
    );
  }
}
