import { Box, Text } from "grommet";
import React, { Component } from "react";

export default class componentName extends Component {
  state = {
    loadingSeconds: [],
  };

  componentDidMount() {
    this.timer = setInterval(() => {
      let newArray = [...this.state.loadingSeconds];
      if (newArray.length <= 4) {
        this.setState({ loadingSeconds: [...newArray, 1] });
      } else {
        this.setState({ loadingSeconds: [1] });
      }
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <Box fill justify="center" align="center">
        <Text level="3" color="light-6" textAlign="center">
          {this.props.text || "Loading"}
          {this.state.loadingSeconds.map((sec, index) => (
            <span key={`key_${index}`}>.</span>
          ))}
        </Text>
      </Box>
    );
  }
}
